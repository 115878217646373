<template>
  <div>
    <el-breadcrumb separator="/" style="margin-bottom: 20px;">
      <el-breadcrumb-item :to="{ name: 'index' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{name: 'task.index'}">任务管理</el-breadcrumb-item>
      <el-breadcrumb-item> 编辑任务 </el-breadcrumb-item>
    </el-breadcrumb>

    <el-steps :active="active" finish-status="success">
      <el-step title="任务信息"></el-step>
      <el-step title="提交内容"></el-step>
    </el-steps>

    <br/>

    <el-form
        ref="task.edit"
        :rules="formData.rules"
        label-width="80px"
        :model="formData.data"
    >
      <el-row v-show="active === 0">
        <el-col :span="9" :xs="24">
          <el-form-item label="选择合同" prop="project_contract_id">
            <el-select clearable style="width: 100%" filterable v-model="formData.data.project_contract_id" placeholder="选择合同">
              <el-option
                  v-for="(item, key) in projects"
                  :key="key"
                  :value="item.id"
                  :label="item.project.name + item.remark + ' ' + item.start_at +'~'+ item.end_at">
              </el-option>
            </el-select>
          </el-form-item>
          <!--
          <el-form-item label="图片" prop="image">
            <el-upload
                class="avatar-uploader"
                accept="image/jpeg,image/png,image/jpg"
                :http-request="uploadFile"
                :show-file-list="false"
            >
              <img v-if="formData.data.image" :src="formData.data.image" alt="图" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">+</el-icon>
            </el-upload>
          </el-form-item>
          -->
          <el-form-item label="任务类型" prop="type">
            <el-select clearable style="width: 100%" filterable v-model="formData.data.type" placeholder="类型">
              <el-option :value="1" label="销量/刷单" />
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.data.name" show-word-limit :maxlength="20" />
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="formData.data.desc" show-word-limit :maxlength="50" />
          </el-form-item>
          <el-form-item label="任务数" prop="number">
            <el-input-number v-model="formData.data.number" :readonly="formData.data.id" style="width: 100%" placeholder="任务数量" />
          </el-form-item>
          <el-form-item label="限购数" prop="limit">
            <el-input-number v-model="formData.data.limit" style="width: 100%" placeholder="限购数量" />
          </el-form-item>
          <el-form-item label="佣金" prop="price">
            <el-input-number v-model="formData.data.price" :precision="2" :controls="false" placeholder="不小于0.01" />&nbsp;&nbsp;元
          </el-form-item>
          <el-form-item label="最大佣金" prop="max_price">
            <el-input-number v-model="formData.data.max_price" :precision="2" :controls="false" placeholder="不小于0.01，不填写则和佣金一致" />&nbsp;&nbsp;元
          </el-form-item>
          <el-form-item label="权重" prop="sort">
            <el-input-number v-model="formData.data.sort" :controls="false" placeholder="数值越大越前" />
          </el-form-item>

          <el-form-item label="有效期" prop="expired_at">
            <el-date-picker
                v-model="formData.data.expired_at"
                editable
                clearable
                :disabled-date="disabledDate"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="选择截止日期"
            />
          </el-form-item>

          <el-button type="primary" @click="nextTask()">下一步</el-button>
        </el-col>
      </el-row>

      <div v-show="active === 1">
      <el-row>
        <el-col :span="12" :xs="24">
          <el-form-item label="内容" prop="content">
            <div style="border: 1px solid #ccc;">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor.editor"
                  :defaultConfig="editor.toolbarConfig"
                  :mode="editor.mode"
              />
              <Editor
                  style="height: 300px; overflow-y: hidden;"
                  v-model="formData.data.content"
                  :defaultConfig="editor.editorConfig"
                  :mode="editor.mode"
                  @onCreated="onCreated"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12" :xs="24">
          <el-form-item label="任务内容" prop="content">
            <div style="border: 1px solid #ccc;">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="priEditor.editor"
                  :defaultConfig="priEditor.toolbarConfig"
                  :mode="priEditor.mode"
              />
              <Editor
                  style="height: 300px; overflow-y: hidden;"
                  v-model="formData.data.private_content"
                  :defaultConfig="priEditor.editorConfig"
                  :mode="priEditor.mode"
                  @onCreated="onPriCreated"
              />
            </div>
          </el-form-item>

        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="info" @click="preTask()" size="large">上一步</el-button>
        <el-button v-if="formData.data.id" type="warning" size="large" @click.stop="onSubmit">修改任务</el-button>
        <el-button v-else type="primary" size="large" @click.stop="onSubmit">创建任务</el-button>
      </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import {apiGetQiniuToken} from "@/api/commonApi"
import {apiCreateTask, apiUpdateTask, apiGetTaskShow} from "@/api/taskApi"
import randomstring from 'randomstring'
import * as qiniu from 'qiniu-js'
import { apiGetProjectContracts } from '@/api/projectContractApi'
import {getCompanyId} from "@/plugins/company"
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'task.edit',
  components: { Editor, Toolbar },
  created() {
    this.getProjectContract()

    if (this.$route.query.id) {
      this.formData.data.id = this.$route.query.id
      this.showTask()
    }
  },
  data () {
    return {
      active: 0,
      projects: [],
      formData: {
        lock: false,
        data: {
          id: 0,
          type: 1,
          project_contract_id: '',
          name: '',
          desc: '',
          number: '',
          limit: 1,
          price: 0,
          max_price: 0,
          sort:  0,
          expired_at: '',
          content: '<p>内容</p>',
          private_content: '<p>内容</p>',
        },
        rules: {
          project_contract_id: [{required: true, message: '请选择合同', trigger: 'blur'}],
          number: [{required: true, message: '填写数量', trigger: 'blur'}],
          type: [{required: true, message: '请选择类型', trigger: 'blur'}],
          limit: [{required: true, message: '填写限制', trigger: 'blur'}],
          expired_at: [{required: true, message: '任务有效期', trigger: 'blur'}],
          name: [{required: true, message: '请填写输入名称', trigger: 'blur'},{min: 2, max: 20, message: '字符在2~20字之间', trigger: 'blur'}],
          price: [{required: true, message: '请填写价格', trigger: 'blur'}],
          sort:  [{required: true, message: '请填写权重', trigger: 'blur'}],
        }
      },
      editor:{
        editor: null,
        toolbarConfig: { },
        editorConfig: {
          placeholder: '请输入内容...' ,
          maxLength: 2000 ,
          scroll: true,
          MENU_CONF: {
            uploadImage: {
              customUpload (file, insertFn) {
                let ext = file.name.substr(file.name.lastIndexOf(".")+1)
                let name = randomstring.generate(16)
                let path = `notice/thumb-${name}.${ext}`

                apiGetQiniuToken({path: path}).then(data => {
                  const observable = qiniu.upload(file, path, data.token)

                  return observable.subscribe(null, (err) => {
                    console.log(err)
                  }, (success) => {
                    insertFn(data.domain + success.key, "", "")
                  })
                })
              }
            }
          }
        },
        mode: 'simple', // or 'default'
      },
      priEditor: {
        editor: null,
        toolbarConfig: { },
        editorConfig: {
          placeholder: '请输入内容...',
          maxLength: 2000,
          scroll: true,
          MENU_CONF: {
            uploadImage: {
              customUpload (file, insertFn) {
                let ext = file.name.substr(file.name.lastIndexOf(".")+1)
                let name = randomstring.generate(16)
                let path = `notice/thumb-${name}.${ext}`

                apiGetQiniuToken({path: path}).then(data => {
                  const observable = qiniu.upload(file, path, data.token)

                  return observable.subscribe(null, (err) => {
                    console.log(err)
                  }, (success) => {
                    insertFn(data.domain + success.key, "", "")
                  })
                })
              }
            }
          }
        },
        mode: 'simple', // or 'simple'
      }
    }
  },
  methods: {
    disabledDate (time) {
      return time.getTime() < Date.now()
    },
    // 获取任务详情
    showTask () {
      apiGetTaskShow(this.formData.data.id).then(res =>{
        this.formData.data = res
      })
    },
    getProjectContract () {
      apiGetProjectContracts({company_id: getCompanyId(), status:1, per_page: 200, with_query: ['project']}).then(res =>{
        this.projects = res.data
      })
    },
    nextTask () {
      this.$refs['task.edit'].validate(valid => {
        if (valid) {
          this.active = 1
        }
      })
    },
    preTask () {
      this.active = 0
    },
    onSubmit () {
      if (this.formData.lock) {
        return
      }
      this.formData.lock = true
      this.$refs['task.edit'].validate(valid => {
        if (valid) {
          if (this.formData.data.id) {
            apiUpdateTask(this.formData.data.id, this.formData.data).then(() =>{
              this.$message.success('修改成功')
              this.$router.push({name: 'task.index'})
            })
          } else {
            apiCreateTask(this.formData.data).then(() =>{
              this.$message.success('新增成功')
              this.$router.push({name: 'task.index'})
            })
          }

          this.formData.lock = false
        }else {
          this.formData.lock = false
        }
      })
    },
    onCreated(editor) {
      this.editor.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onPriCreated (editor) {
      this.priEditor.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
