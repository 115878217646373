<template>
<div>
  <div v-if="data.id" style="text-align: center">
    <div>
      <p v-text="data.task.desc"></p>
      <el-image
          style="width: 200px; height: 400px"
          :src="data.image"
      ></el-image>
      <p><a :href="data.image" target="image">查看大图</a> <span @click="updatePrice()">修改价格</span></p>
      <p><b>ID: {{data.id}}&nbsp;&nbsp;&nbsp;售价：{{ data.task.max_price }}&nbsp;&nbsp;&nbsp;申请价格：{{data.price}}</b></p>
      <p>购买人: <img :src="data.member.avatar" alt="" width="80"> {{ data.member.nickname }}</p>
    </div>
    <br><br>
    <div>
      <el-button type="success" @click="onAudit()">审核通过</el-button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <el-button type="danger" @click="clickPass()">审核拒绝</el-button>
    </div>
  </div>
  <div v-else>
    <div style="text-align: center;margin-top: 200px;">
      <h4>没有需要审核的内容了</h4>
    </div>
  </div>

  <!-------- 弹出框表单 start ---------->
  <el-dialog v-model="dialog" title="审核" fullscreen append-to-body>
    <el-form
        ref="contract.pay"
        label-width="100px"
        :model="formData">
      <el-form-item label="退回原因" prop="pay_type">
        <el-select v-model="formData.fail_text" >
          <el-option value="申请的金额与实付不符，请重新提交"></el-option>
          <el-option value="店铺购买错误，请退款后重新购买正确的店铺"></el-option>
          <el-option value="活动已结束，商家不需要了，请申请退款"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onPass">提交</el-button>
        </span>
    </template>
  </el-dialog>
  <!-------- 弹出框表单 end ---------->

</div>
</template>


<script>

import {apiGetTaskRecordSaleIndex, apiAuditTaskRecordSale, apiPassTaskRecordSale} from "@/api/taskApi"
export default {
  data () {
    return {
      dialog: false,
      data: {},
      formData: {
        fail_text: '申请的金额与实付不符',
      },
    }
  },
  created() {
    this.getRecordSale()
  },
  methods:{
    getRecordSale() {
      apiGetTaskRecordSaleIndex().then(res => {
        this.data = res
      })
    },
    onAudit () {
      this.$confirm("确定审核通过吗").then(() =>{
        apiAuditTaskRecordSale(this.data.id).then(() =>{
          this.$message.success('审核操作成功，即将返回下一条待处理信息')
          this.getRecordSale()
        })
      })
    },
    clickPass () {
      this.dialog = true
    },
    updatePrice () {

    },
    onPass() {
      apiPassTaskRecordSale(this.data.id, this.formData).then(() =>{
        this.$message.success('审核操作成功，即将返回下一条待处理信息')
        this.getRecordSale()
        this.dialog = false
      })
    }
  }
}
</script>
