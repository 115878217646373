<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="任务">
          <el-select clearable style="width: 120px" filterable v-model="searchData.task_id" placeholder="任务">
            <el-option
                v-for="(item, key) in tasks"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width:120px;" v-model="searchData.status" placeholder="状态搜索" clearable>
            <el-option :value="1" label="待处理" />
            <el-option :value="2" label="进行中" />
            <el-option :value="3" label="待结算" />
            <el-option :value="10" label="已结算" />
            <el-option :value="20" label="已失效" />
            <el-option :value="15" label="已失败" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="searchData.member_id" placeholder="用户ID搜索"></el-input>
        </el-form-item>
        <el-form-item label="核销时间">
          <el-date-picker
              v-model="searchData.confirm_at"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="getTaskRecord()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button @click="exportData()">导出</el-button>
          <el-button @click="exportAlipay()">导出待支付</el-button>
          <el-button @click="importAlipay()">导入</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="task.project_name" label="合同名"/>
      <el-table-column prop="task.id" label="任务ID"/>
      <el-table-column prop="task.name" label="任务名"/>
      <el-table-column label="用户">
        <template #default="scope">
          <el-avatar :src="scope.row.member.avatar"></el-avatar>
          {{ scope.row.member.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="status_desc" label="名称"/>
      <el-table-column prop="price" label="佣金"/>
      <el-table-column prop="created_at" label="创建时间"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
              type="primary"
              size="small"
              v-show="scope.row.status === 1" @click="upSale(scope.row)">
            提交
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTaskRecord()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="核销链接" fullscreen append-to-body>
      <el-form
          ref="upSale"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="二维码" prop="image">
          <el-upload
              class="avatar-uploader"
              v-model:file-list="formData.data.lists"
              accept="image/jpeg,image/png,image/jpg"
              :http-request="uploadFile"
              :show-file-list="false"
          >
            <img v-if="formData.data.image" :src="formData.data.image" alt="图" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">+</el-icon>
          </el-upload>
        </el-form-item>

        <el-form-item label="券码" prop="code">
          <el-input v-model="formData.data.code" type="number" placeholder="券码"/>
        </el-form-item>

        <el-form-item label="金额" prop="price">
          <el-input v-model="formData.data.price" type="number" placeholder="实付金额"/>
        </el-form-item>

      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="formData.show = false">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end -------->

  </div>
</template>

<script>
import {apiGetTaskRecordIndex, apiGetTaskIndex, apiExportTaskRecordAlipay, apiExportTaskRecord, apiTaskRecordUpSale} from "@/api/taskApi"
import {getCompanyId} from "@/plugins/company"
import {apiGetQiniuToken} from "@/api/commonApi"
import randomstring from 'randomstring'
import * as qiniu from 'qiniu-js'
import QrScanner from 'qr-scanner'

export default {
  created() {
    this.getTask()
    this.getTaskRecord()
  },
  data() {
    return {
      searchData:{
        task_id: '',
        keyword: '',
        status: '',
        page: 1,
        per_page: 15,
        confirm_at: [],
        company_id: getCompanyId(),
      },
      pageData:{
        total: 0
      },
      tasks:[],
      tableData:[],
      formData: {
        lock: false,
        id: 0,
        data: {
          image: '',
          code: '',
          price: ''
        },
        rules: {
          image: [{
            required: true,
            message: '请提交图片',
            trigger: 'blur',
          }],
          code: [{
            required: true,
            message: '请输入券号',
            trigger: 'blur',
          }],
          price: [{
            required: true,
            message: '请输入金额',
            trigger: 'blur',
          }]
        },
      },
    }
  },
  methods:{
    getTaskRecord () {
      apiGetTaskRecordIndex(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    },
    exportAlipay () {
      window.open(apiExportTaskRecordAlipay(this.searchData))
    },
    importAlipay () {
      this.$router.push({
        name: 'task.record.import',
      })
    },
    getTask () {
      apiGetTaskIndex({company_id: getCompanyId(), per_page: 200}).then(res =>{
        this.tasks = res.data
      })
    },
    // 后台提交数据
    upSale (row) {
      this.formData.id = row.id
      this.formData.show = true
    },
    // 图片上传
    uploadFile(file) {
      QrScanner.scanImage(file.file).then(code =>{
        if (parseInt(code) > 0) {
          this.upupload(file, code)
        } else {
          this.$message.error('请上传有效的核销码截图(1)')
        }
      }).catch((err) =>{
        console.log(err)
        this.upupload(file, '')
      })
    },
    upupload (file, code) {
      let vue = this
      let ext = file.file.name.substr(file.file.name.lastIndexOf(".") + 1)
      let name = randomstring.generate(16)
      let path = `h5/task/manage-sale-${name}.${ext}`

      apiGetQiniuToken({path: path}).then(data => {
        const observable = qiniu.upload(file.file, path, data.token)

        observable.subscribe(null, (err) => {
          console.log(err)
        }, (success) => {
          vue.formData.data.code = code
          vue.formData.data.image = data.domain + success.key

          file.onSuccess({
            name: name,
            url: data.domain + success.key
          })
        })
      })
    },
    onSubmit () {
      this.$refs['upSale'].validate((valid) =>{
        if (valid) {
          apiTaskRecordUpSale(this.formData.id, this.formData.data).then(() =>{
            this.$message.success('提交成功')
            this.formData.show = false
          })
        }
      })
    },
    exportData () {
      window.open(apiExportTaskRecord(this.searchData))
    }
  }
}
</script>
