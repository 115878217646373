import {httpGet, httpPost, httpPut} from '@/plugins/request'
import {getApiUrl} from "@/plugins/common";
import {getAccessToken} from "@/plugins/auth";

/**
 * 任务列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTaskIndex(query = [])
{
    return httpGet('/manage/task/task', query)
}


/**
 * 创建任务
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateTask(data = [])
{
    return httpPost('/manage/task/task', data)
}

/**
 * 更新任务
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateTask(id, data = [])
{
    return httpPut(`/manage/task/task/${id}`, data)
}


/**
 * 任务详情
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTaskShow(id, query = [])
{
    return httpGet(`/manage/task/task/${id}`, query)
}


/**
 * 任务上架
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiOnlineTask(id, query = [])
{
    return httpPost(`/manage/task/task/${id}/online`, query)
}

/**
 * 任务结束
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiCompleteTask(id, query = [])
{
    return httpPost(`/manage/task/task/${id}/complete`, query)
}


/**
 * 任务记录列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTaskRecordIndex(query = [])
{
    return httpGet(`/manage/task/record`, query)
}

/**
 * 获取待审核列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetTaskRecordSaleIndex(query = [])
{
    return httpGet(`/manage/task/record/sale/review`, query)
}

/**
 * 通过审核
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiAuditTaskRecordSale(id, data = [])
{
    return httpPost(`/manage/task/record/sale/${id}/audit`, data)
}

/**
 * 拒绝审核
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiPassTaskRecordSale(id, data = [])
{
    return httpPost(`/manage/task/record/sale/${id}/pass`, data)
}


/**
 * 商家链接
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiGetTaskProjectIndex(data = [])
{
    return httpGet(`/manage/task/project`, data)
}

/**
 * 商家链接
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreTaskProject(data = [])
{
    return httpPost(`/manage/task/project`, data)
}


/**
 * 商家链接
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateTaskProject(id, data = [])
{
    return httpPut(`/manage/task/project/${id}`, data)
}


/**
 * 商家链接
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiDisableTaskProject(id, data = [])
{
    return httpPost(`/manage/task/project/${id}/disable`, data)
}

/**
 * 提交核销码
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiTaskRecordUpSale(id, data = [])
{
    return httpPost(`/manage/task/record/${id}/up-sale`, data)
}


/**
 * 增加导出功能
 * @param query
 * @returns {string}
 */
export function apiExportTaskRecordAlipay(query) {
    query.token = getAccessToken()
    return getApiUrl('/manage/task/record/export/alipay', query)
}

/**
 * 导入记录
 * @returns {string}
 * @param data
 */
export function apiImportTaskRecordAlipay(data) {
    return httpPost(`/manage/task/record/import/alipay`, data)
}

export function apiExportTaskRecord(query = {}) {
    query.token = getAccessToken()
    return getApiUrl('/manage/task/record/export/data', query)
}
