import { createRouter, createWebHistory } from "vue-router"
import {checkLogin} from './auth'

import routes from '@/routes'

// 3. 创建路由实例
const router = createRouter({
    // （1）采用hash 模式
    // history: createWebHashHistory(),
    // （2）采用 history 模式
    history: createWebHistory(),
    routes, //使用上方定义的路由配置
})

router.beforeEach((to) => {
    let isLogin = checkLogin()

    const ignore = [
        // 退出的不验证
        'login', 'logout', 'socialite.login',
        // 分享的走单独的逻辑
        'share', 'share.note.index', 'share.wechat.check-task', 'share.check.data', 'share.check.total', 'share.check.hexiao',
        'share.wework.project', 'share.wechat.check-task', 'share.project.padding-pay', 'share.project.padding-renew', 'share.project.numbers',
        // 企业微信的不认证
        'wework.project', 'wework.person', 'wework.impress', 'wework.contract'
    ]

    let indexOf = ignore.indexOf(to.name)

    if (!isLogin && indexOf <= -1) {
        return {name: 'login', query: {redirect_url: window.location.href}}
    }

    return true
})

// 4. 导出router
export default router
