<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="合同">
          <el-select clearable style="width: 120px" filterable v-model="searchData.project_contract_id" placeholder="合同">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.project.name + item.remark + ' ' + item.start_at +'~'+ item.end_at">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchData.type" clearable>
            <el-option :value="1" label="刷单/销量" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.status" multiple clearable>
            <el-option :value="0" label="待上架" />
            <el-option :value="1" label="进行中" />
            <el-option :value="10" label="已完成" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="searchData.keyword" placeholder="关键字搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTask()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="success" @click.stop="clickCreate()">添加任务</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="contract_name" label="合同名"/>
      <el-table-column prop="type_desc" label="类型"/>
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="price" label="佣金"/>
      <el-table-column prop="status_desc" label="状态"/>
      <el-table-column label="剩余/总数">
        <template #default="scope">
         {{ scope.row.balance }} /{{ scope.row.number }}
        </template>
      </el-table-column>
      <el-table-column prop="padding_count" label="待核销" />
      <el-table-column prop="record_count" label="完成数" />
      <el-table-column prop="limit" label="限购" />
      <el-table-column prop="expired_at" label="过期时间"/>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row.id)">修改</el-button>
          <el-button type="danger" size="small" v-if="scope.row.status === 0" @click="clickOnline(scope.row)">上架</el-button>
          <el-button type="danger" size="small" v-if="scope.row.status !== 10" @click="clickComplete(scope.row)">结束</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTask()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

  </div>
</template>

<script>
import { apiGetProjectContracts } from '@/api/projectContractApi'
import {apiGetTaskIndex, apiOnlineTask, apiCompleteTask} from "@/api/taskApi"
import {getCompanyId} from "@/plugins/company"

export default {
  created() {
    this.getProjectContract()
    this.getTask()
  },
  data() {
    return {
      searchData:{
        project_contract_id: '',
        keyword: '',
        type: '',
        status: [0, 1],
        page: 1,
        per_page: 15,
        company_id: getCompanyId(),
      },
      pageData:{
        total: 0
      },
      projects:[],
      tableData:[],
    }
  },
  methods:{
    getTask () {
      apiGetTaskIndex(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    },
    getProjectContract () {
      apiGetProjectContracts({company_id: getCompanyId(), status:1, per_page: 200, with_query: ['project']}).then(res =>{
        this.projects = res.data
      })
    },
    clickCreate () {
      this.$router.push({
        name: 'task.edit',
      })
    },
    clickUpdate (id) {
      this.$router.push({
        name: 'task.edit',
        query:{
          id: id
        }
      })
    },
    clickOnline (row) {
      apiOnlineTask(row.id).then(() =>{
        this.$message.success('操作成功')
        this.getTask()
      })
    },
    clickComplete(row) {
      apiCompleteTask(row.id).then(() =>{
        this.$message.success('操作成功')
        this.getTask()
      })
    }
  }
}
</script>
